import { useAuthStore } from '@jernia/shared/state/auth';

import { UserGroup } from '@jernia/shared/types/user';

export function isAuthorized() {
  const role = useAuthStore.getState().userRole;
  return role !== 'anonymous';
}

// TODO Replace with
export function getUserGroup(): UserGroup {
  return useAuthStore.getState().userRole;
}

export function getAlgoliaUserToken(): string | undefined {
  const customerId = useAuthStore.getState().customerId;

  if (typeof window === 'undefined' || !customerId) {
    return undefined;
  }

  return customerId === 'anonymous' ? undefined : customerId;
}

export function getPersistentAlgoliaUserToken(): string | undefined {
  const userToken = getAlgoliaUserToken();

  if (userToken) {
    return userToken;
  }
  const sessionId = getSessionId();

  return sessionId ? `anonymous-${sessionId}` : 'anonymous';
}

export function getSessionId() {
  const session = useAuthStore.getState().session;
  return session?.id || '';
}
