import { getUserGroup } from 'jernia.no/helpers/user';
import { useQuery } from 'react-query';

import { config } from '../lib/config';
import { useAuthStore } from '../state/auth';
import { fetchWithAuthOptional } from '../state/fetch';
import type { JerniaProduct } from '../types/product';

export async function getProducts(productIds: string[]) {
  return await Promise.all(
    productIds.map((id) =>
      fetch(`${config.apiBaseUrl}/products/${id}`).then((resp) => {
        if (!resp.ok) {
          return null;
        }

        return resp.json();
      })
    )
  ).then((products) => products.filter((product) => product !== null));
}

export function useProducts(productIds: string[]): JerniaProduct[] {
  const queryInfo = useQuery(
    ['products', ...productIds],
    () => {
      if (!productIds || productIds.length === 0) {
        return [];
      }

      return getProducts(productIds);
    },
    {
      retry: 0,
    }
  );

  return queryInfo.isSuccess ? queryInfo.data : [];
}

export function useUserProduct(productId?: string) {
  const { userId } = useAuthStore();
  const userGroup = getUserGroup();
  return useQuery<unknown, unknown, JerniaProduct | null>(
    ['product', `${productId}-${userGroup}`],
    async () => {
      const response = await fetchWithAuthOptional(
        `${config.apiBaseUrl}/users/${userId}/products/${productId}`
      );
      if (!response.ok) {
        return null;
      }

      const result = await response.json();
      return result ?? null;
    },
    {
      retry: 0,
      enabled: typeof productId === 'string',
    }
  );
}

export function useProduct(productId: string): JerniaProduct | null {
  const products = useProducts([productId]);
  return products[0] || null;
}

export function useDeliveryEstimateQuery({
  zip,
  productCode,
}: {
  zip: string | null;
  productCode: string;
}) {
  return useQuery(
    ['esitmate', zip, productCode],
    async () => {
      const params = new URLSearchParams();
      params.append('postalCode', zip || '');

      const response = await fetch(
        `${config.apiBaseUrl}/products/${productCode}/deliveryestimate?${params}`
      );

      return response.json();
    },
    {
      enabled: zip !== null,
    }
  );
}

export function useProductPrice(productCode: string) {
  const { userId } = useAuthStore();

  return useQuery(
    ['product/prices', productCode],
    async () => {
      const urlParams = new URLSearchParams({
        products: productCode,
        fields: 'DEFAULT',
      });

      const response = await fetchWithAuthOptional(
        `${config.apiBaseUrl}/users/${userId}/prices?${urlParams.toString()}`
      );

      return response.json();
    },
    {
      enabled: typeof productCode === 'string',
      retry: 0,
    }
  );
}
