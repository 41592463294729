import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';
import { useMemo } from 'react';

import Button from '@jernia/shared/components/button/button';
import Drawer from '@jernia/shared/components/drawer/drawer';
import {
  ArrowLeftIcon,
  CopyFilesIcon,
  DropdownIcon,
  ExternalLinkOpenIcon,
  FileRemoveIcon,
  HeadsetIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  StoreIcon,
  TabletLargePhoneIcon,
  UserProfileIcon,
} from '@jernia/shared/components/icons/icons';

import {
  useAuthStore,
  useLoggedInEmployee,
  useStores,
} from '@jernia/shared/state/auth';

import { useIsApp } from '@jernia/shared/hooks/hub';

import { Logo } from '../logo/logo';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onChangeStore: () => void;
};

function parseEmployeeUid(
  employeeUid: string
): [prefix: string, storeId: string, employeeCode: string] {
  const [prefix, storeId, employeeCode] = employeeUid.split('-');
  return [prefix, storeId, employeeCode];
}

export function MainMenu({ isOpen, onClose, onChangeStore }: Props) {
  const employee = useLoggedInEmployee();
  const { logout } = useAuthStore();

  const stores = useStores();
  const isApp = useIsApp;

  let employeeStoreId;
  if (employee.isSuccess) {
    [, employeeStoreId] = parseEmployeeUid(employee.data.uid);
  }

  const activeStoreId =
    employee.data?.overrideStore?.vismaStoreId ?? employeeStoreId;

  const activeStore = useMemo(() => {
    return stores.data?.find((store) => store.vismaStoreId === activeStoreId);
  }, [activeStoreId, stores.data]);

  return (
    <Drawer isOpen={isOpen} onRequestClose={onClose}>
      <div className="flex h-screen flex-col overflow-y-auto bg-primary-800 p-8">
        <div className="grow">
          <div className="mb-10 flex justify-between">
            <Logo />
            <button
              className="text-white focus:text-primary-500 focus:outline-none"
              onClick={onClose}
            >
              <ArrowLeftIcon size={6} />
            </button>
          </div>

          <button
            className="mb-8 flex w-full rounded bg-white p-4 text-left shadow-lg focus:outline-none focus:ring"
            onClick={() => {
              onClose();
              onChangeStore();
            }}
          >
            <div className="flex size-12 items-center justify-center rounded-full bg-primary-100">
              <HomeIcon size={6} className="text-primary" />
            </div>
            <div className="ml-4 font-semibold">
              <div className="text-sm">Innlogget butikk</div>
              <div className="text-primary">{activeStore?.name}</div>
            </div>
            <div className="ml-auto mt-2 flex flex-col">
              <DropdownIcon className="-mb-4 rotate-180" />
              <DropdownIcon className="mt-2" />
            </div>
          </button>

          <nav>
            <section className="mb-10">
              <h5 className="mb-6 font-semibold uppercase text-white">
                Hub-tjenester
              </h5>

              <ul className="space-y-3 font-semibold">
                <li
                  className={classNames('text-white', {
                    'text-primary-500': isApp('b2h'),
                  })}
                >
                  <Link href="/b2h" className="flex">
                    <TabletLargePhoneIcon size={6} className="mr-3" />
                    <span>B2H</span>
                    {isApp('b2h') && (
                      <div className="ml-auto w-0.5 rounded-sm bg-primary-500" />
                    )}
                  </Link>
                </li>

                <li
                  className={classNames('text-white', {
                    'text-primary-500': isApp('rental'),
                  })}
                >
                  <Link href="/rental" className="flex">
                    <StoreIcon size={6} className="mr-3" />
                    <span>UTLEIE</span>
                    {isApp('rental') && (
                      <div className="ml-auto w-0.5 rounded-sm bg-primary-500" />
                    )}
                  </Link>
                </li>

                <li
                  className={classNames('text-white', {
                    'text-primary-500': isApp('ticketing'),
                  })}
                >
                  <Link href="/tickets" className="flex">
                    <CopyFilesIcon size={6} className="mr-3" />
                    <span>REKLAMASJON</span>
                    {isApp('ticketing') && (
                      <div className="ml-auto w-0.5 rounded-sm bg-primary-500" />
                    )}
                  </Link>
                </li>

                <li
                  className={classNames('text-white', {
                    'text-primary-500': isApp('customers'),
                  })}
                >
                  <Link href="/customers" className="flex">
                    <UserProfileIcon size={6} className="mr-3" />
                    <span>KUNDEKLUBB</span>
                    {isApp('customers') && (
                      <div className="ml-auto w-0.5 rounded-sm bg-primary-500" />
                    )}
                  </Link>
                </li>
              </ul>
            </section>

            <section className="text-white">
              <h5 className="mb-6 font-semibold uppercase text-white">
                Snarvei
              </h5>
              <ul className="space-y-6 font-semibold">
                <li>
                  <a
                    className="flex hover:text-primary-500"
                    href="https://www.jernia.no/kundeservice"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <HeadsetIcon size={6} className="mr-3" /> Kundeservice
                  </a>
                </li>
                <li>
                  <a
                    className="flex hover:text-primary-500"
                    href="https://www.jernia.no/kundeservice#faq"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <QuestionMarkCircleIcon size={6} className="mr-3" />
                    Ofte stilte spørsmål
                  </a>
                </li>
                <li>
                  <a
                    className="flex hover:text-primary-500"
                    href="https://www.jernia.no/salgsbetingelser#angrerett"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FileRemoveIcon size={6} className="mr-3" />
                    Angrerett
                  </a>
                </li>
              </ul>
            </section>
          </nav>
        </div>

        <div className="my-8 flex items-center border-t border-primary-300 pt-8">
          <div className="grow">
            <p className="mb-2 text-xl font-semibold text-primary-500">
              {employee.data?.name}
            </p>
            <p className="text-sm text-white">
              <b>ID:</b> {employee.data?.uid}
              <br />
              <b>Butikk:</b> {activeStore?.name}
            </p>
          </div>
        </div>
        <div>
          <Button
            size="small"
            className="whitespace-nowrap"
            onClick={() => logout()}
            rightIcon={<ExternalLinkOpenIcon size={6} />}
          >
            Logg ut
          </Button>
        </div>
      </div>
    </Drawer>
  );
}
