import { liteClient } from 'algoliasearch/lite';
import React from 'react';
import { InstantSearch } from 'react-instantsearch';

import {
  API_KEY,
  APP_ID,
  PRODUCT_INDEX_NAME,
} from '@jernia/shared/lib/algolia';

const searchClient = liteClient(APP_ID, API_KEY);

export function InstantSearchContainer({
  routing,
  children,
}: {
  routing?: any | undefined;
  children: React.ReactNode;
}) {
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={PRODUCT_INDEX_NAME}
      routing={routing}
    >
      {children}
    </InstantSearch>
  );
}
