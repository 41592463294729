import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { UseQueryResult, useQuery } from 'react-query';

import { Breadcrumb } from '@jernia/shared/components/breadcrumbs/breadcrumbs';

import { useLoggedInEmployee } from '@jernia/shared/state/auth';
import { useCart } from '@jernia/shared/state/cart/queries';
import { useCartStore } from '@jernia/shared/state/cart/store';
import { createStore } from '@jernia/shared/state/createStore';
import { useCustomer } from '@jernia/shared/state/customer';

import { useRichRelevance } from '@jernia/shared/helpers/richrelevance/hooks';
import { RichRelevanceResult } from '@jernia/shared/helpers/richrelevance/types';
import { Facets, buildQueryString } from '@jernia/shared/helpers/solr';
import {
  getProductFilters,
  getProductSortIndicies,
} from '@jernia/shared/lib/algolia';
import { config } from '@jernia/shared/lib/config';

import { JerniaProduct } from '@jernia/shared/types/product';
import { Facet, SolrProductResult } from '@jernia/shared/types/solr';

export function useProducts(productIds: string[]): JerniaProduct[] {
  const productCodes = productIds.join(',');

  const queryInfo = useQuery(
    ['products', ...productIds],
    () => {
      if (!productCodes) {
        return [];
      }

      // @TODO: Don't proxy in production
      return fetch(`/api/products?codes=${productCodes}`).then((resp) =>
        resp.json()
      );
    },
    {
      retry: 0,
    }
  );

  return queryInfo.isSuccess ? queryInfo.data : [];
}

export function useProduct(productId: string): JerniaProduct | null {
  const products = useProducts([productId]);
  return products[0] || null;
}

function mapProductIds(result: UseQueryResult<RichRelevanceResult>): string[] {
  if (!result.isSuccess || result.data?.status !== 'ok') {
    return [];
  }

  return (
    result.data.placements?.[0]?.recommendedProducts?.map(
      (product) => product.id
    ) || []
  );
}

export function useRelatedProducts(productId?: string): JerniaProduct[] {
  const rrData = useRichRelevance({
    placements: ['item_page.rank1'],
    productIds: productId ? [productId] : [],
    categoryIds: ['1701'],
  });

  return useProducts(mapProductIds(rrData));
}

export function useAccessoryProducts(productId?: string) {
  const rrData = useRichRelevance({
    placements: ['item_page.rank2'],
    productIds: productId ? [productId] : [],
    categoryIds: ['1701'],
  });

  const productIds = mapProductIds(rrData);
  return useSolrSearch({
    codes: mapProductIds(rrData),
    enabled: productIds.length > 0,
  });
}

export function useBoughtTogetherProducts(productId?: string) {
  const rrData = useRichRelevance({
    placements: ['add_to_cart_page.modal'],
    productIds: productId ? [productId] : [],
  });

  const productIds = mapProductIds(rrData);
  return useSolrSearch({
    codes: productIds,
    enabled: productIds.length > 0,
  });
}

export function useLipscore(
  productId: string,
  { enabled = true }: { enabled?: boolean } = {}
) {
  type Result = {
    id: number;
    lipscore: string;
    product_instance_id: number;
    review_count: number;
    votes: number;
  };

  return useQuery<Result>(
    ['starRating', productId],
    async () => {
      const resp = await fetch(
        `https://wapi.lipscore.com/initial_data/products/show?api_key=b043a32b7a98ea1bd2d7527b&internal_id=${productId}&widgets=r_sm,rw_c`
      );
      return await resp.json();
    },
    { staleTime: Infinity, enabled }
  );
}

export function useStarRating(
  productId: string,
  { enabled = true }: { enabled?: boolean } = {}
): number {
  const { data } = useLipscore(productId, { enabled });

  if (!enabled) {
    return 0;
  }

  return data?.lipscore ? Number((Number(data.lipscore) / 2).toFixed(1)) : 0;
}

export function useQueryParams() {
  const { asPath } = useRouter();

  const [, queryString] = asPath.split('?');

  return useMemo(() => {
    const urlParams = new URLSearchParams(queryString);
    return Object.fromEntries(urlParams.entries());
  }, [queryString]);
}

export function useQueryParam(key: string) {
  const { asPath } = useRouter();

  const [, queryString] = asPath.split('?');
  const urlParams = new URLSearchParams(queryString);

  return urlParams.get(key) || '';
}

export function useSolrSearch(
  {
    query = '',
    codes,
    sort = 'relevance',
    page = 0,
    facets = {},
    enabled = true,
  }: {
    codes?: string[];
    query?: string;
    sort?: string;
    page?: number;
    facets?: Facets;
    enabled?: boolean;
  },
  initialResult?: SolrProductResult
) {
  const searchQuery = buildQueryString({ query, sort, page, facets, codes });
  return useQuery<SolrProductResult>(
    ['solr', searchQuery],
    async () => {
      const resp = await fetch(
        `${config.apiBaseUrl}/solr/products?${searchQuery}`
      );
      return (await resp.json()) as SolrProductResult;
    },
    {
      initialData: initialResult,
      keepPreviousData: true,
      enabled,
    }
  );
}

type SolrHooks = {
  addFacet: (id: string, value: string) => void;
  removeFacet: (id: string, value: string) => void;
  activeFacets: Facet[];
};
export function useSolrHooks(
  result: SolrProductResult | undefined,
  facets: Facets,
  setFacets: (newFacets: Facets) => void
): SolrHooks {
  const router = useRouter();

  function resetPage() {
    const { page, ...query } = router.query;
    router.push(
      {
        pathname: router.pathname,
        query: query,
      },
      undefined,
      {
        shallow: true,
      }
    );
  }

  function addFacet(id: string, value: string) {
    if (!facets[id]) {
      setFacets({
        ...facets,
        [id]: [value],
      });
    } else {
      setFacets({
        ...facets,
        [id]: [...facets[id], value],
      });
    }

    resetPage();
  }

  function removeFacet(id: string, value: string) {
    if (facets[id]) {
      setFacets({
        ...facets,
        [id]: facets[id].filter((v) => v !== value),
      });

      resetPage();
    }
  }

  const activeFacets = Object.keys(facets)
    .map((facetKey) => {
      if (result?.facets) {
        return result?.facets.find((facet) => facet.code === facetKey);
      }
      return null;
    })
    .filter(Boolean) as Facet[];

  return { activeFacets, addFacet, removeFacet };
}

export function useCustomerPhone() {
  const { data: cart } = useCart();
  const { tmpAddressInfo } = useCartStore();
  const customer = useCustomer();

  if (customer.data?.mobilePhone) {
    return customer.data.mobilePhone;
  }

  if (cart?.deliveryAddress?.phone) {
    return cart.deliveryAddress.phone;
  }

  if (customer.data?.defaultAddress?.phone) {
    return customer.data.defaultAddress.phone;
  }

  return tmpAddressInfo.phone;
}

export type ApiSite = 'cnSite' | 'jernia_no_site';

interface ApiSiteState {
  site: ApiSite;
}

export const useApiSite = createStore<ApiSiteState>(() => ({
  site: 'cnSite',
}));

export const useRentalSearchParams = () => {
  const { data: employee } = useLoggedInEmployee();

  const customRentalParams = {
    utleie: '1',
    utleielager: employee?.store?.name ?? '',
  };

  const rentalSearchParams = new URLSearchParams(customRentalParams).toString();

  return rentalSearchParams;
};

export const useIsRentalSearch = () => {
  const router = useRouter();
  const isRental = Boolean(router?.query?.utleie);
  return isRental;
};

export const useFilterBreadrumbs = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const isRental = useIsRentalSearch();
  const rentalSearchParams = useRentalSearchParams();

  const normalisedCrumbs = breadcrumbs.map((crumb) => {
    return {
      ...crumb,
      url: crumb?.url ? crumb.url : crumb.storefrontUrl,
    };
  });

  const filteredCrumbs = isRental
    ? normalisedCrumbs.map((crumb) => {
        return {
          ...crumb,
          url: `${crumb.url}?${rentalSearchParams}`,
        };
      })
    : normalisedCrumbs;

  return filteredCrumbs;
};
